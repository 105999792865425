import { responsive } from '../../utils/helpers'

const select = {
  width: '290px',
  bottom: '40px',
  right: '50px'
}

const styles = {
  container: {
    position: "relative",
    height: '350px',
  },
  background: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  layout: {
    flexDirection: 'column',
    height: '100%',
    position: 'relative'
  },
  content: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  selectRight: {
    ...select,
    position: responsive('static', 'absolute', 8),
    mt: responsive(2, 0, 8),
  },
  selectCenter: {
    ...select,
    mx: responsive(0, 2, 8),
    mb: responsive(2, 0, 8)
  },
  heading: {
    textAlign: 'center'
  },
  flex: {
    pt: 2,
    display: responsive('block', 'flex', 8),
  }
 }

 export default styles
/** @jsx jsx */
import { jsx, Box, Flex, Heading } from 'theme-ui'
import Background from './background'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { responsive } from '../../utils/helpers'
import MatchdayPreview from '../../images/matchday-preview.png'
import MatchdayReport from '../../images/matchday-report.png'
import VideoIcon from '../../images/fgr-live.png'
import { GatsbyImage } from "gatsby-plugin-image";

const MultipleImages = (props) => {

  const layoutSX = {
    position: 'relative',
    display: 'flex',
    height: '100%'
  }

  const containerSX = {
    width: '100%', 
    height: '553px',
    position: 'relative'
  }

  const captionSX = {
    padding: props.carousel ? '80px 0' : '40px 0',
    flexDirection: 'column',
    zIndex: 1,
    alignItems: 'flex-start',
    justifyContent: props.centeredTitle ? 'center' : 'flex-end',
    width: props.centeredTitle ? responsive('100%', '42%') : responsive('100%', '55%')
  }

  const imageContainerSX = {
    width: responsive('calc(100% + 50px)', '55%'),
    height: responsive('100%', 'auto'),
    position: 'absolute',
    right: responsive('-25px', '0px'),
    left: responsive('-25px', 'auto'),
    bottom: responsive('0px', '20px'),
    top: responsive('0px', '20px'),

    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)',
      opacity: '0.7',
      display: responsive('block', 'none')
    }
  }

  const imageSX = {
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
  }

  const iconSX = {
    width: '60px',
    mb: 2,
  }

  const matchdaySX = {
    width: responsive('355px', '455px'),
    position: 'relative',
    left: responsive('-30px', '-55px')
  }

  const headingSX = {
    fontSize: responsive(10, '100px'),
    color: 'primary'
  }

  return (
    <Box sx={containerSX}>
      <Background image={props.background.src} gatsbyImg={props.gatsbyBgImg} />
      <Wrapper sx={layoutSX}>
        <Inner sx={layoutSX} as="figure">
          <Flex as="figcaption" sx={captionSX}>
              {props.variant === 'matchday' &&
                <img sx={matchdaySX} src={MatchdayPreview} alt="Matchday Preview" />
              }
              {props.variant === 'matchdayReport' &&
                <img sx={matchdaySX} src={MatchdayReport} alt="Matchday Report" />
              }
              {props.variant === 'FGR Live Stream' &&
                <Box>
                  <img src={VideoIcon} sx={iconSX} alt="" />
                  <Heading sx={headingSX}>FGR Live</Heading>
                </Box>
              }
              {props.children}
          </Flex>
          {(props.image?.src || props.gatsbyImg) &&
            <Box sx={imageContainerSX}>
              {props.gatsbyImg &&
                <GatsbyImage image={props.gatsbyImg} sx={imageSX} alt={props.image.alt} />
              }
              {!props.gatsbyImg &&
                <img 
                  sx={imageSX} 
                  src={props.image.src} 
                  alt={props.image.alt} 
                />
              }
              
            </Box>
          }
          </Inner>
      </Wrapper>
    </Box>
  );
}

export default MultipleImages
/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import { responsive } from '../../utils/helpers'

const Title = (props) => {

  const headingSX = {
    fontSize: props.variant === 'matchdayReport' ? '75px' : responsive(10, '100px'),
    lineHeight: 'compact',
    color: props.variant === 'fgr live preview' ? 'primary' : 'white',
    fontFamily: 'headingalt',
  }

  return <Heading as={props.as} sx={headingSX} className={props.className}>{props.children}</Heading>
}

export default Title
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Carousel from '../carousel'
import { responsive } from '../../utils/helpers'

const HeroCarousel = (props) => {

  const sliderSX = {

    '.slick-dots': {
      position: 'absolute',
      bottom: '25px',
      maxWidth: '1305px',
      width: '100%',
      padding: responsive('0px 25px', '0px 90px'),
      transform: 'translateX(-50%)',
      left: '50%',
      textAlign: 'left',

      '> li': {
        
        'button': {
          width: '12px',
          height: '12px',
          backgroundColor: 'white',
          borderRadius: '50%',

          '&:before': {
            display: 'none'
          }
        },

        '&.slick-active button': {
          backgroundColor: 'primary'
        }
      }
    }
  }

  return (
    <Carousel sx={sliderSX} className={props.className}>
      {props.children}
    </Carousel>
  )
}

export default HeroCarousel
/** @jsx jsx */
import { jsx, Heading, Box } from 'theme-ui'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import Pitch from '../../images/pitch.png'
import Select from 'react-select'
import { selectStyles } from '../../styles/select'
import styles from './archive.styles'
import { responsive } from '../../utils/helpers'

const Archive = (props) => {

  return (
    <Box sx={styles.container}>
      <img sx={styles.background} src={Pitch} alt="" />
      <Wrapper sx={styles.layout}>
        <Inner sx={styles.layout}>
          <Box 
            sx={{
              ...styles.content,
              pb: props.variant === 'center' ? responsive(0, 6, 8) : 6
            }}
          >
            <Heading sx={styles.heading}>{props.title}</Heading>
            <Box sx={props.variant === 'center' ? styles.flex : {}}>
              {props.filters?.map((x,i) => {
                if (!x.options) return
                return (
                  <Box 
                    sx={props.variant === 'center' ? styles.selectCenter : styles.selectRight}
                    key={i}
                  >
                    <Select 
                      styles={selectStyles}
                      isSearchable={false}
                      defaultValue={x.options[0]}
                      options={x.options} 
                      onChange={x.onChange}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Inner>
      </Wrapper>
    </Box>
  )
}

export default Archive
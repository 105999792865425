/** @jsx jsx */
import { jsx, Button, Text, Flex, Box } from 'theme-ui'
import { Link } from 'gatsby'
import Archive from './archive'
import HeroLayout from './layout'
import HeroTitle from './title'
import HeroTag from './tag'
import Bar from './bar'
import Bio from './bio'
import MultipleImages from './multipleImages'
import HeroCarousel from './carousel'
import HeroOpponent from './opponent'
import NextMatch from '../nextMatch'
import Arrow from '../../icons/arrow-back.svg'
import { responsive } from '../../utils/helpers'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = props => {
  const getVariant = x => {
    switch (x.type) {
      case 'Matchday Preview':
        return 'matchday'
      case 'Matchday Report':
        return 'matchdayReport'
      case 'FGR Live Preview':
        return 'fgr live preview'
      default:
        return x.type
    }
  }

  const containerSX = {
    position: 'relative',
  }

  const descriptionSX = {
    fontSize: '28px',
    lineHeight: 'body',
    color: 'white',
    fontWeight: 200,
  }

  const actionsSX = {
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    paddingTop: responsive(10, 4),
    paddingX: 2,
    zIndex: '1',
  }

  const buttonSX = {
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    transition: 'all 0.2s linear',

    '&:hover': {
      background: 'black',
      color: 'white',
    },
  }

  const titleSX = {
    lineHeight: 'miniature',
  }

  const Actions = () => {
    if (!props.button && !props.back) return null

    return (
      <Flex sx={actionsSX}>
        {props.back && (
          <Button variant="icon" sx={buttonSX} as={Link} to={props.back}>
            <Arrow />
          </Button>
        )}
        {props.button && (
          <Button
            variant="secondary"
            sx={buttonSX}
            as="a"
            href={props.button.url}
          >
            {props.button.label}
          </Button>
        )}
      </Flex>
    )
  }

  return (
    <Box className={props.className} sx={containerSX}>
      <Actions />
      <HeroCarousel>
        {props.data?.map((x, i) => {
          const variant = getVariant(x)
          const Title = variant === 'matchday' ? HeroOpponent : HeroTitle
          switch (variant) {
            case 'Image Only':
              return (
                <HeroLayout
                  key={i}
                  background={x.backgroundImage?.file?.url}
                  gatsbyBgImg={x.backgroundImage?.gatsbyImageData}
                  title={x.title}
                  strap={props.strap}
                  variant={props.variant}
                  mobileRatio={props.mobileRatio}
                  height={props.height}
                  fade
                />
              )
            case 'Next Match':
              return (
                <HeroLayout
                  background={x.backgroundImage?.file?.url}
                  gatsbyBgImg={x.backgroundImage?.gatsbyImageData}
                  key={i}
                >
                  <NextMatch
                    sx={{ position: 'relative' }}
                    title="Next Match"
                    variant="large"
                    tickets
                    live
                    // travel
                  />
                </HeroLayout>
              )
            case 'Custom':
              return (
                <HeroLayout
                  background={x.backgroundImage?.file?.url}
                  gatsbyBgImg={x.backgroundImage?.gatsbyImageData}
                  key={i}
                >
                  {x.children}
                </HeroLayout>
              )
            case 'Bio':
              return <Bio {...x} key={i} />
            default:
              return (
                <MultipleImages
                  key={i}
                  background={{ src: x.backgroundImage?.file?.url }}
                  gatsbyBgImg={x.backgroundImage?.gatsbyImageData}
                  image={{ src: x.foregroundImage?.file?.url }}
                  gatsbyImg={x.foregroundImage?.gatsbyImageData}
                  variant={variant}
                  carousel={props.data.length > 1}
                  centeredTitle={
                    props.centeredTitle || variant === 'News Story'
                  }
                >
                  {x.tag && <HeroTag>Tag name</HeroTag>}
                  <Title
                    as={i === 0 ? 'h1' : 'h2'}
                    variant={variant}
                    sx={titleSX}
                  >
                    {x.title}
                  </Title>
                  {x.description && (
                    <Text as="p" sx={descriptionSX}>
                      {x.description}
                    </Text>
                  )}
                  {x.buttonLabel &&
                    x.buttonUrl &&
                    variant !== 'fgr live preview' &&
                    !x.buttonComponent && (
                      <Button as={Link} mt={2} to={x.buttonUrl}>
                        {x.buttonLabel}
                      </Button>
                    )}
                  {x.buttonComponent}
                  {variant === 'fgr live preview' && (
                    <Button as={Link} mt={2} to="/join">
                      Get Access Now
                    </Button>
                  )}
                </MultipleImages>
              )
          }
        })}
      </HeroCarousel>
    </Box>
  )
}

export default Hero
export {
  Archive,
  HeroLayout,
  HeroTitle,
  Bar,
  Bio,
  MultipleImages,
  HeroCarousel,
  HeroTag,
  HeroOpponent,
}

/** @jsx jsx */
import { jsx, Heading, Text } from 'theme-ui';

const HeroOpponent = (props) => {

  const headingSX = {
    fontSize: '75px',
    fontFamily: 'heading',
    color: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    mb: 2,
  }

  const vsSX = {
    fontFamily: 'light',
    mr: 1,
  }

  return <Heading sx={headingSX} className={props.className}><Text sx={vsSX}>Vs</Text>{props.children}</Heading>
}

export default HeroOpponent
/** @jsx jsx */
import { jsx, Flex, Box, Text } from 'theme-ui'
import BarGreen from '../../images/bar-green.png'
import BarGold from '../../images/bar-gold.png'

const Bar = (props) => {

  const containerSX = {
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    background: `transparent url(${props.variant === 'gold' ? BarGold : BarGreen}) left top / 1440px 110px repeat`
  }

  const contentSX = {
    position: 'relative',
    background: 'primary',
    width: '100%',
    minHeight: '110px',
    alignItems: 'center',
    justifyContent: 'center',
    pl: 4,
    pr: 4
  }

  const headingSX = {
    color: 'black'
  }

  return (
    <Box sx={containerSX}>
      <Flex sx={contentSX}>
        <Text variant="heading" sx={headingSX}>{props.children}</Text>
      </Flex>
    </Box>
  )
}

export default Bar
/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx, Box, Text } from 'theme-ui'
import Bar from './bar'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { useMediaQuery } from 'react-responsive'
import { responsive } from '../../utils/helpers'
import { Inline } from 'raam'
import QuoteMarks from '../../icons/quote.svg'

const Bio = (props) => {

  const [imageSrc, setImageSrc] = useState()
  const [numImage, setNumImage] = useState()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 961px)' })

  const parsePrimaryColour = (colour) => {
    switch (colour) {
      case 'pink':
        return 'goalkeeper'
      default:
      case 'green':
        return 'primary'
    }
  }

  const containerSX = {
    position: "relative",
    height: '0px',
    paddingBottom: responsive('193%', '580px'),
    backgroundColor: props.primaryColour ? parsePrimaryColour(props.primaryColour) : 'primary'
  }

  const imageSX = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: ['center', null, null, null, null, 'left top', null, null, '100px top']
  }

  const wrapperSX = {
    position: 'absolute',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '100%'
  }

  const innerSX = {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: responsive('column', 'row'),
    justifyContent: responsive('flex-end', 'flex-start'),
    pb: responsive(4, '0px')
  }

  const numberSX = {
    position: 'absolute',
    height: '328px',
    width: 'auto',
    top: 15,
    left: '0px'
  }

  const statContainerSX = {
    position: responsive('static', 'absolute'),
    bottom: 11,
    left: '40px',
    overflowX: 'visible',
  }

  const statHeadingSX = {
    fontSize: 0,
    fontWeight: 700,
    textAlign: responsive('left', 'center'),
    textTransform: 'uppercase'
  }

  const statValueSX = {
    fontFamily: 'heading',
    fontSize: 10,
    textAlign: responsive('left', 'center'),
    color: responsive('white', 'black')
  }

  const contentSX = {
    width: ['100%', null, null, null, null, '30%', null, '40%', '35%'],
    marginLeft: 'auto',
    display: responsive('block', 'flex'),
    flexDirection: 'column',
    height: responsive('auto', '100%'),
    justifyContent: 'center'
  }

  const positionSX = {
    mb: responsive(5, 0),
    display: 'inline-block',
    position: 'relative',
    paddingRight: responsive(1, '0px'),
    py: responsive(1, '0px')
  }

  const positionBar = {
    width: 'calc(100% + 25px)',
    height: '100%',
    backgroundColor: 'black',
    position: 'absolute',
    left: '-25px',
    top: '0px',
  }

  const positionTextSX = {
    position: 'relative',
    color: 'white',
    fontSize: responsive(6, 0),
    letterSpacing: responsive('0.1em', 'auto'),
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: responsive('headingalt', 'body')
  }

  const nameSX = {
    color: responsive('white', props.primaryColour ? parsePrimaryColour(props.primaryColour) : 'primary'),
    fontSize: responsive('80px', '120px', 8),
    fontFamily: 'heading',
    lineHeight: '0.8em',
    mb: 6
  }

  const quoteSX = {
    fontFamily: 'heading',
    fontSize: [null, null, null, null, null, 8, null, null, 9],
    color: 'white'
  }

  const quoteMarkSX = {
    color: 'white',
    display: 'block',
    width: '42px',
    height: '24px',
    fill: 'white'
  }

  const quoteTopSX = {
    ...quoteMarkSX,
    mb: 2,
  }

  const quoteBottomSX = {
    ...quoteMarkSX,
    transform: 'rotate(180deg)',
    mt: 2,
  }

  useEffect(() => {
    const importNumber = async () => {
      import(`../../images/numbers/${props.number}.png`)
      .then((Number) => {
        setNumImage(Number.default)
      })
      .catch((error) => {
        console.log(error);
      })
    }
    importNumber()
  }, [props.number])

  const ShowNumber = () => {
    if (!props.number || !numImage || isTabletOrMobile)
      return null

    return <img src={numImage} alt={props.number} sx={numberSX} />
  }

  const Stat = (x) => {
    return (
      <Box>
        <Text sx={statHeadingSX}>{x.label}</Text>
        <Text sx={statValueSX}>{x.value}</Text>
      </Box>
    )
  }

  const Stats = () => {
    if (!props.stats || !Array.isArray(props.stats))
      return null

    return (
      <Inline sx={statContainerSX} gap={4}>
        {props.stats.map((x,i) => {
          return (
            <Stat {...x} key={i} />
          )
        })}
      </Inline>
    )
  }

  useEffect(() => {
    setImageSrc(
      isTabletOrMobile 
        ? props.background.mobile 
        : props.background.desktop
    )
  }, [
    isTabletOrMobile
  ])

  return (
    <Box sx={containerSX}>
      <img sx={imageSX} src={imageSrc} alt="" />
      <ShowNumber />
      <Wrapper sx={wrapperSX}>
        <Inner sx={innerSX}>
          <Box sx={contentSX}>
            <Box sx={positionSX}>
              {isTabletOrMobile &&
                <Box sx={positionBar} />
              }
              <Text sx={positionTextSX}>{props.position}</Text>
            </Box>
            <Text sx={nameSX}>{props.name}</Text>
            {isDesktop &&
              <Text sx={quoteSX}><QuoteMarks sx={quoteTopSX} />{props.quote} <QuoteMarks sx={quoteBottomSX} /></Text>
            }
            
          </Box>
        </Inner>
      </Wrapper>
      {props.bar &&
        <Bar>{props.bar}</Bar>
      }
    </Box>
  )
}

export default Bio
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { GatsbyImage } from "gatsby-plugin-image";

const Background = ({ image, gatsbyImg, product, isDesktop }) => {
  const img = {
    position: 'absolute !important',
    top: '0px',
    left: '0px',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
  }

  if (gatsbyImg) {
    return (
      <GatsbyImage
        image={gatsbyImg}
        sx={img}
        imgStyle={
          product && isDesktop
            ? { objectPosition: 'top right' }
            : { objectPosition: '62%' }
        }
        alt="" />
    );
  }

  return <img src={image} sx={img} alt="" />
}

export default Background

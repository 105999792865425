/** @jsx jsx */
import { jsx, Text } from 'theme-ui';

const Tag = (props) => {

  const style = {
    fontFamily: 'heading',
    fontSize: 9,
    color: 'primary',
    mb: 3,

  }

  return <Text as={props.as} sx={style}>{props.children}</Text>
}

export default Tag
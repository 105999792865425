/** @jsx jsx */
import { jsx, Box, Flex, Heading, Text } from 'theme-ui'
import Pitch from '../../images/pitch.png'
import Background from './background'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import Bar from './bar'

import BarWhite from '../../images/bar-white.png'
import BarGrey from '../../images/bar-grey.png'
import BarGold from '../../images/bar-gold.png'
import { responsive } from '../../utils/helpers'

const Central = props => {
  const containerSX = {
    position: 'relative',
    height: props.mobileRatio
      ? responsive('400px', '580px')
      : props.height
      ? props.height
      : '580px',
  }

  const layoutSX = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
  }

  const contentSX = {
    ...layoutSX,
    justifyContent: props.variant === 'center' ? 'center' : 'flex-end',
    width: '100%',
    alignItems: props.variant === 'corner' ? 'flex-start' : 'center',
  }

  const fadeSX = {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
    backgroundColor: props.variant === 'light' ? 'white' : 'grey',

    '&:before': {
      content: '""',
      background: `transparent url(${
        props.variant === 'light'
          ? BarWhite
          : props.variant === 'gold'
          ? BarGold
          : BarGrey
      }) left top / 1440px 110px repeat-x`,
      height: '110px',
      position: 'absolute',
      top: '-110px',
      left: '0',
      width: '100%',
    },
  }

  const titleSX = {
    marginTop: '-70px',
    position: 'relative',
  }

  const headingSX = {
    alignItems: 'center',
    flexWrap: 'wrap',
  }

  const strapSX = {
    fontSize: 8,
  }

  return (
    <Box sx={containerSX}>
      <Background
        image={props.background ? props.background : Pitch}
        gatsbyImg={props.gatsbyBgImg}
        product={props.product}
        isDesktop={props.isDesktop}
        alt=""
      />
      <Wrapper sx={layoutSX}>
        <Inner sx={layoutSX}>
          <Flex sx={contentSX} pb={4}>
            {props.children}
          </Flex>
        </Inner>
      </Wrapper>
      {props.bar && <Bar variant={props.variant}>{props.bar}</Bar>}
      {props.fade && (
        <Box sx={fadeSX}>
          {props.title && (
            <Wrapper sx={titleSX}>
              <Inner>
                <Flex sx={headingSX}>
                  <Heading sx={{ color: 'black' }} mr={5} as="h1">
                    {props.title}
                  </Heading>
                  {props.strap && <Text sx={strapSX}>{props.strap}</Text>}
                </Flex>
              </Inner>
            </Wrapper>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Central
